<template>
  <div class="file-image clearfix">
    <div v-show="isReady" class="buttons">
      <el-button v-if="canDownload" type="primary" icon="el-icon-download" circle @click="handleDownload"></el-button>
      <el-button v-if="downloading" type="primary" icon="el-icon-loading" circle @click="handleDownload"></el-button>
      <el-button v-if="canDelete" type="primary" circle icon="el-icon-delete" @click="handleRemove"></el-button>
    </div>
    <span v-show="!isReady" class="status">{{ Math.floor(file.percentage) + '% ' }} </span>
    <span> {{ $t('fileUpload.fileName') }}: {{ file.name }}</span>
    <span class="description">{{ file.description }}</span>
    <div v-show="!isReady" class="progress-bar" :style="'width:' + file.percentage + '%'"></div>
  </div>
</template>

<script>
import { openPDFStream } from '@/utils';

export default {
  name: 'FileImage',
  props: {
    file: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    openFileInNewWindow: {
      type: Boolean,
      default: false
    },
    downloadAction: String,
    deleteAction: String,
    showDownloadButton: {
      type: Boolean,
      default: true
    },
    showDeleteButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      actionLoading: false,
      downloading: false
    };
  },
  computed: {
    isReady() {
      return this.file.status === 'success' || this.file.id;
    },
    entityID() {
      return this.$route.params && this.$route.params.id;
    },
    canDownload() {
      return !this.downloading && this.showDownloadButton;
    },
    canDelete() {
      return !this.disabled && this.showDeleteButton;
    }
  },
  methods: {
    handleDownload() {
      this.downloadBlob(this.entityID);
    },
    async downloadBlob(id) {
      this.downloading = true;
      try {
        const { data } = await this.downloadRequest(id);
        if (this.openFileInNewWindow) {
          openPDFStream(data);
          return;
        }
        this.downloadFile(data, this.file.name);
      } catch (err) {
        console.log('download error', err);
      } finally {
        this.downloading = false;
      }
    },
    downloadRequest(id) {
      return this.$store.dispatch(this.downloadAction, id);
    },
    downloadFile(data, name = 'file') {
      const anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(new Blob([data]));
      anchor.download = name;
      anchor.click();
    },
    handleRemove() {
      this.actionLoading = true;
      this.$store
        .dispatch(this.deleteAction, this.entityID)
        .then(() => {
          this.$emit('remove', this.file);
        })
        .finally(() => {
          this.actionLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.file-image {
  padding: 0.5em 0;
  .buttons {
    opacity: 0;
    float: right;
  }
  .status {
    float: right;
  }
  &:hover {
    background: #f5f7fa;
    .buttons {
      opacity: 1;
    }
  }
  .progress-bar {
    display: block;
    height: 2px;
    background: red;
  }
}
.description {
  display: block;
  font-size: 0.8em;
}
</style>
